import {
  axios_default
} from "./chunk-3TVYLYMS.js";

// src/store/UtilsStore/web3.ts
import { stringMessage } from "@concordium/react-components";
import { env } from "aesirx-lib";
var createPreregistration = async (data, jwt) => {
  try {
    return await axios_default.post(`${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
var activation = async (web3id, code) => {
  return await axios_default.put(
    `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/activation/${web3id}/${code}`
  );
};
var getNonce = async (accountAddress, connection, text = "") => {
  try {
    const nonce = (await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/account/${accountAddress}/nonce?text=${text}`
    )).data.nonce;
    const signature = await connection.signMessage(accountAddress, stringMessage(`${nonce}`));
    const signedNonce = Buffer.from(
      typeof signature === "object" && signature !== null ? JSON.stringify(signature) : signature,
      "utf-8"
    ).toString("base64");
    return signedNonce;
  } catch (error) {
    throw error;
  }
};
var getPreregistration = async (jwt) => {
  return await axios_default.get(`${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/aesirx`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt
    }
  });
};
var getDemoData = async (jwt) => {
  try {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/demo/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    return { data: { objForm: false } };
  }
};
var linkAccount = async (web3Id, accountAddress, signedNonce) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/id/${web3Id}/account/${accountAddress}/?signature=${signedNonce}&network=${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`
    );
  } catch (error) {
    throw error;
  }
};
var linkAesirXAccount = async (web3id, jwt) => {
  await axios_default.put(
    `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/aesirx/${web3id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    }
  );
};
var getNonceAesirX = async (accountAddress, connection) => {
  try {
    const nonce = (await axios_default.post(
      `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?api=hal&option=member&task=getWalletNonce&webserviceClient=site&webserviceVersion=1.0.0`,
      {
        publicAddress: accountAddress,
        wallet: "concordium",
        text: "Get data: {}"
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data.result;
    const signature = await connection.signMessage(accountAddress, stringMessage(`${nonce}`));
    const signedNonce = Buffer.from(
      typeof signature === "object" && signature !== null ? JSON.stringify(signature) : signature,
      "utf-8"
    ).toString("base64");
    return signedNonce;
  } catch (error) {
    throw error;
  }
};
var loginAesirXAccount = async (accountAddress, signedNonce) => {
  try {
    return await axios_default.post(
      `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?api=hal&option=member&task=walletLogin&webserviceClient=site&webserviceVersion=1.0.0`,
      {
        publicAddress: accountAddress,
        wallet: "concordium",
        signature: JSON.parse(Buffer.from(signedNonce, "base64").toString())
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  } catch (error) {
    throw Error(error);
  }
};
var createAesirXAccount = async (accountAddress, connection, email) => {
  try {
    const nonce = (await axios_default.post(
      `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=reditem&view=item_wallet_requests_66&api=hal`,
      {
        public_address: accountAddress,
        wallet: "concordium",
        text: "Create AesirX Account : {}"
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data?.nonce;
    const signature = await connection.signMessage(accountAddress, stringMessage(`${nonce}`));
    const signedNonce = typeof signature === "object" && signature !== null ? signature : JSON.parse(signature);
    const aesirXID = (await axios_default.post(
      `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=reditem&view=item_wallet_requests_66&task=validateSignature&api=hal`,
      {
        public_address: accountAddress,
        wallet: "concordium",
        signature: signedNonce,
        email
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data?.result?.id;
    return aesirXID;
  } catch (error) {
    throw error;
  }
};
var savePreregistration = async (jwt, data) => {
  try {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("first_name", data.first_name);
    formData.append("sur_name", data.sur_name);
    formData.append("organization", data.organization);
    formData.append("avatar", data.avatar);
    return await axios_default.put(`${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration`, data, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + jwt
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
var joinTestnet = async (accountAddress, signedNonce, jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/add/${accountAddress}?signature=${signedNonce}&network=${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw Error(error?.response?.data?.error);
  }
};
var addWhitelist = async (jwt) => {
  try {
    return await axios_default.post(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/demo/whitelist/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw Error(error?.response?.data?.error);
  }
};
var transactionWeb3NFT = async (jwt, transaction) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/demo/nft/web3id/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/${transaction}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw Error(error?.response?.data?.error);
  }
};
var getNFTMetaData = async (jwt) => {
  try {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/interests/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    return { data: {} };
  }
};
var createInterests = async (jwt, web3id, interests) => {
  try {
    const formData = {};
    formData["id"] = web3id;
    formData["network"] = env.NEXT_PUBLIC_CONCORDIUM_NETWORK;
    Object.keys(interests).forEach((index) => {
      formData["interests[" + index + "]"] = interests[index];
    });
    return await axios_default.post(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/interests/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var updateInterests = async (jwt, web3id, interests) => {
  try {
    const formData = {};
    formData["id"] = web3id;
    formData["network"] = env.NEXT_PUBLIC_CONCORDIUM_NETWORK;
    Object.keys(interests).forEach((index) => {
      formData["interests[" + index + "]"] = interests[index];
    });
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/interests/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}`,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var getWe3IdMetadata = async (web3id) => {
  try {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/demo/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/web3id/${web3id}`
    );
  } catch (error) {
    console.log(error);
  }
};
var shortenString = (str, first = 6, last = 4) => {
  return str?.substring(0, first) + "..." + str?.substring(str.length - last);
};
var walletLogin = async (wallet, accountAddress, signedNonce) => {
  try {
    const data = (await axios_default.post(
      `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?api=hal&option=member&task=walletLogin&webserviceClient=site&webserviceVersion=1.0.0`,
      {
        wallet,
        publicAddress: accountAddress,
        signature: signedNonce
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data?.result;
    return data;
  } catch (error) {
    throw error;
  }
};
var acceptConsent = async (jwt, web3id) => {
  try {
    const signatureJson = JSON.stringify({
      id: web3id,
      url: "web3id.aesirx.io",
      consent: "interests"
    });
    const signature = Buffer.from(signatureJson).toString("base64");
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/interests/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/consent?signature=${signature}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var claimCCD = async (jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/claimccd_60`,
      { network: env.NEXT_PUBLIC_CONCORDIUM_NETWORK },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var claimCCD40 = async (jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/claimccd_40`,
      { network: env.NEXT_PUBLIC_CONCORDIUM_NETWORK },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var claimAESIRX = async (jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/claimaesirx`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var claimOldShare2Earn = async (jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/claimshare2earn`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var checkAccountAvailable = async (accountAddress) => {
  try {
    return (await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/checkwallet/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/${accountAddress}`
    )).data.result;
  } catch (error) {
    return true;
  }
};
var validateWeb3Id = async (id) => {
  try {
    return !(await axios_default.get(`${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/checkid/${id}`)).data.result;
  } catch (error) {
    return false;
  }
};
var validateEmail = async (email) => {
  try {
    const [validateOnWeb3id, validateOnAesirx] = await Promise.all([
      !(await axios_default.get(`${env.REACT_APP_WEB3_API_ENDPOINT}/preregistration/checkemail/${email}`)).data.result,
      !(await axios_default.post(
        `${env.NEXT_PUBLIC_AESIRX_API_ENDPOINT}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&task=checkEmailIsUsed&api=hal`,
        {
          email
        }
      )).data.result
    ]);
    return validateOnWeb3id && validateOnAesirx;
  } catch (error) {
    return false;
  }
};
var getPoolList = async (isFeature) => {
  try {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/stakepool${isFeature ? "?pool_feature=true" : ""}`
    );
  } catch (error) {
    console.error("error", error);
  }
};
var stake = async (amount, id, jwt) => {
  try {
    return await axios_default.put(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/demo/${env.NEXT_PUBLIC_CONCORDIUM_NETWORK}/stake/${id}/${amount}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    throw error;
  }
};
var getShare2Earn = async (jwt) => {
  try {
    return await axios_default.get(`${env.REACT_APP_WEB3_API_ENDPOINT}/share2earn`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  } catch (error) {
    throw error;
  }
};
var getShare2EarnList = async (isActive = false) => {
  try {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT}/share2earn/list${isActive ? "?active=true" : ""}`
    );
  } catch (error) {
    throw error;
  }
};
var autoRegisterWeb3id = async (data, clientJwt, signedNonce, walletAccount) => {
  try {
    return await axios_default.post(`/api/autocreate`, {
      data,
      clientJwt,
      signedNonce,
      walletAccount
    });
  } catch (error) {
    console.log("autoRegisterWeb3id", error);
    throw error;
  }
};
var getStatistic = async () => {
  try {
    return await axios_default.get(`${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/statistic`);
  } catch (error) {
    console.log("getStatistic", error);
    throw error;
  }
};
var claimNFT = async (jwt) => {
  try {
    return await axios_default.post(
      `${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/aesirx/account/claim`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    console.log("claimNFT", error);
    throw error;
  }
};
var getChallenge = async (walletAccount) => {
  try {
    return (await axios_default.get(
      `${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/challenge?account=${walletAccount}`
    )).data?.challenge;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var getStatement = async () => {
  try {
    return (await axios_default.get(`${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/statement`)).data;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var verifyProof = async (challenge, proof) => {
  try {
    return (await axios_default.post(
      `${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/prove`,
      {
        challenge,
        proof
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data?.result;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var getAffiliate2earn = async (jwt) => {
  try {
    return await axios_default.get(`${process?.env?.REACT_APP_WEB3_API_ENDPOINT}/affitiliate2earn `, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  } catch (error) {
    console.log("getAffiliate2earn", error);
    throw error;
  }
};

export {
  createPreregistration,
  activation,
  getNonce,
  getPreregistration,
  getDemoData,
  linkAccount,
  linkAesirXAccount,
  getNonceAesirX,
  loginAesirXAccount,
  createAesirXAccount,
  savePreregistration,
  joinTestnet,
  addWhitelist,
  transactionWeb3NFT,
  getNFTMetaData,
  createInterests,
  updateInterests,
  getWe3IdMetadata,
  shortenString,
  walletLogin,
  acceptConsent,
  claimCCD,
  claimCCD40,
  claimAESIRX,
  claimOldShare2Earn,
  checkAccountAvailable,
  validateWeb3Id,
  validateEmail,
  getPoolList,
  stake,
  getShare2Earn,
  getShare2EarnList,
  autoRegisterWeb3id,
  getStatistic,
  claimNFT,
  getChallenge,
  getStatement,
  verifyProof,
  getAffiliate2earn
};
