import {
  MemberItemModel
} from "./chunk-BGVHLUW7.js";
import {
  InstanceServiceApi_default
} from "./chunk-OELFY4PC.js";
import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";
import {
  AUTHORIZATION_KEY,
  AXIOS_CONFIGS
} from "./chunk-4UXUCZ6D.js";
import {
  env
} from "./chunk-3MLWYAAL.js";
import {
  logout
} from "./chunk-AJUXIRE2.js";

// src/Gateway/Instance.ts
import axios3 from "axios";
import queryString from "query-string";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// src/Authentication/Authentication.ts
import axios2 from "axios";

// src/Member/Member.ts
import axios from "axios";
var AesirxMemberApiService = class {
  constructor(props = null) {
    this.route = null;
    this.route = new MemberRoute_default();
    if (props) {
      this.mode = props.mode ?? null;
    }
  }
  /**
   * Get A New Access Token adapts for cases OUT OF Platform Dashboard
   * Such as Forgot Pass / Sign Up Normal or Login Via Social Medias (Facebook / Twitter / Google)
   * @returns {Boolean}
   */
  async refreshANewTokenOnWebBrowser() {
    try {
      const AUTHORIZED_CODE_URL2 = BaseRoute_default.__createRequestURL(
        {
          option: "token",
          api: "oauth2"
        },
        false
      );
      const clientID2 = process.env.OAUTH_CLIENT_ID !== void 0 && process.env.OAUTH_CLIENT_ID !== "" ? process.env.OAUTH_CLIENT_ID : AXIOS_CONFIGS.CLIENT_ID;
      const clientSecret2 = process.env.OAUTH_CLIENT_SECRET !== void 0 && process.env.OAUTH_CLIENT_SECRET !== "" ? process.env.OAUTH_CLIENT_SECRET : AXIOS_CONFIGS.CLIENT_SECRET;
      const defaultUsername = process.env.OAUTH_DEFAULT_USERNAME !== void 0 && process.env.OAUTH_DEFAULT_USERNAME !== "" ? process.env.OAUTH_DEFAULT_USERNAME : AXIOS_CONFIGS.USERNAME;
      const defaultPassword = process.env.OAUTH_DEFAULT_PASSWORD !== void 0 && process.env.OAUTH_DEFAULT_PASSWORD !== "" ? process.env.OAUTH_DEFAULT_PASSWORD : AXIOS_CONFIGS.PASSWORD;
      let reqAuthFormData = null;
      if (true) {
        reqAuthFormData = new FormData();
        reqAuthFormData.append("grant_type", "password");
        reqAuthFormData.append("client_id", clientID2);
        reqAuthFormData.append("client_secret", clientSecret2);
        reqAuthFormData.append("username", defaultUsername);
        reqAuthFormData.append("password", defaultPassword);
      } else {
        reqAuthFormData = {
          grant_type: "password",
          client_id: clientID2,
          client_secret: clientSecret2,
          username: defaultUsername,
          password: defaultPassword
        };
      }
      const result = await axios.post(AUTHORIZED_CODE_URL2, reqAuthFormData);
      let authorizationHeader = "";
      let tokenType = "";
      let accessToken = "";
      if (result && result.data) {
        tokenType = result.data.token_type ?? "Bearer";
        accessToken = result.data.access_token ?? "";
        authorizationHeader = authorizationHeader.concat(tokenType).concat(" ").concat(accessToken);
        if (false) {
          process.env.AUTHORIZED_TOKEN = accessToken;
        } else {
          Storage.setItem(AUTHORIZATION_KEY.ACCESS_TOKEN, accessToken);
          Storage.setItem(AUTHORIZATION_KEY.TOKEN_TYPE, tokenType);
          Storage.setItem(AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER, authorizationHeader);
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  /**
   * Create a Member
   */
  async createMember(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfCreation(data);
      return await this.route.createMemberRequest(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  /**
   * Activate Member
   */
  async activateMember(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfActivation(data);
      return await this.route.activateMemberRequest(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async getTokenByUser() {
    try {
      return await this.route.getTokenByUserRequest();
    } catch (error) {
      return error;
    }
  }
  async getFacebookAdsAppAccessToken() {
    try {
      return await this.route.getFacebookAdsAppAccessToken();
    } catch (error) {
      return error;
    }
  }
  /**
   * @param memberId
   *  */
  async getMemberInfo(memberId = 0) {
    try {
      const memberInfoResponse = await this.route.getMemberInfoRequest(memberId);
      let memberItemModel = new MemberItemModel();
      return memberItemModel.toJSON(memberInfoResponse);
    } catch (error) {
      return null;
    }
  }
  /**
   * Update Member
   */
  async updateMember(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfUpdateMember(data);
      return await this.route.updateMemberRequest(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  /**
   * Update member password
   */
  async updateMemberPassword(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfUpdateMemberPassword(data);
      return await this.route.updateMemberRequest(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async resendActivationEmail(memberId) {
    try {
      return await this.route.resendActivationEmailRequest(memberId);
    } catch (error) {
      return error;
    }
  }
  async sendResetPasswordEmail(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfResetMemberEmail(data);
      return await this.route.processResetRequest(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async checkToResetPassword(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfResetMemberActivation(data);
      return await this.route.processResetConfirm(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async newPasswordToResetPassword(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfResetMemberNewPassword(data);
      return await this.route.processResetComplete(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async checkUsername(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfCheckUsername(data);
      return await this.route.checkUsername(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async checkEmail(data) {
    try {
      const dataToSubmit = MemberItemModel.__transformItemToApiOfCheckEmail(data);
      return await this.route.checkEmail(dataToSubmit);
    } catch (error) {
      return false;
    }
  }
  async getPreregistration(jwt) {
    return await axios.get(`${AXIOS_CONFIGS.BASE_ENDPOINT_WEB3_URL}/preregistration/aesirx`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  }
  async updatePreregistration(jwt, data) {
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("first_name", data.first_name);
      formData.append("sur_name", data.sur_name);
      formData.append("organization", data.organization);
      formData.append("avatar", data.avatar);
      return await axios.put(`${AXIOS_CONFIGS.BASE_ENDPOINT_WEB3_URL}/preregistration`, data, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + jwt
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async updateEmailMember(bodyData, accessToken) {
    try {
      const response = await axios.put(
        `${AXIOS_CONFIGS.BASE_ENDPOINT_URL}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&api=hal`,
        bodyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  render() {
    return {};
  }
};

// src/Authentication/Authentication.ts
import qs from "query-string";
var AesirxAuthenticationApiService = class {
  constructor() {
    this.login = async (email, password) => {
      try {
        if (!email || !password)
          return false;
        const AUTHORIZED_CODE_URL2 = BaseRoute_default.__createRequestURL(
          {
            option: "token",
            api: "oauth2"
          },
          false
        );
        const reqAuthFormData = {
          username: email,
          password,
          client_id: AXIOS_CONFIGS.CLIENT_ID,
          client_secret: AXIOS_CONFIGS.CLIENT_SECRET,
          grant_type: "password",
          test_mode: AXIOS_CONFIGS.TEST_MODE,
          domain: AXIOS_CONFIGS.DOMAIN
        };
        const config = {
          method: "post",
          url: AUTHORIZED_CODE_URL2,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: reqAuthFormData
        };
        const { data: result } = await axios2(config);
        if (false) {
          return result;
        }
        if (result?.access_token) {
          return await this.setTokenUser(result, false);
        }
      } catch (error) {
        return error;
      }
    };
    this.socialLogin = async (socialType, accessTokenSocial) => {
      try {
        const routerService = new BaseRoute_default();
        const url = routerService.createRequestURL({
          option: "member",
          task: "socialLogin"
        });
        const data = qs.stringify({
          socialType,
          accessToken: accessTokenSocial,
          timezone_default: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        const config = {
          method: "post",
          url,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data
        };
        const result = await axios2(config);
        if (result.data) {
          return await this.setTokenUser(result.data.result);
        }
        return false;
      } catch (error) {
        return false;
      }
    };
    this.setTokenUser = async (accessTokenData, isSocialLogin = true) => {
      const serviceMember = new AesirxMemberApiService();
      let authorizationHeader = "";
      let tokenType = "";
      let accessToken = "";
      let firstLogin = false;
      let refreshToken2 = "";
      let jwt = "";
      if (accessTokenData) {
        tokenType = accessTokenData.token_type ?? "Bearer";
        accessToken = accessTokenData.access_token ?? "";
        jwt = accessTokenData.jwt ?? "";
        authorizationHeader = authorizationHeader.concat(tokenType).concat(" ").concat(accessToken);
        refreshToken2 = accessTokenData[AUTHORIZATION_KEY.REFRESH_TOKEN] ?? "";
        if (isSocialLogin) {
          firstLogin = accessTokenData.first_login;
        } else {
          firstLogin = accessTokenData?.profile?.lastVisitDate === "0000-00-00 00:00:00";
        }
        const setStore = {
          [AUTHORIZATION_KEY.JWT]: jwt,
          [AUTHORIZATION_KEY.ACCESS_TOKEN]: accessToken,
          [AUTHORIZATION_KEY.TOKEN_TYPE]: tokenType,
          [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER]: authorizationHeader,
          [AUTHORIZATION_KEY.REFRESH_TOKEN]: refreshToken2
        };
        this.setStore(setStore);
        try {
          const tokenUser = await serviceMember.getTokenByUser();
          if (tokenUser.result) {
            const setStore2 = {
              [AUTHORIZATION_KEY.TOKEN_USER]: tokenUser.result.token,
              [AUTHORIZATION_KEY.TOKEN_USER_EXPIRE]: tokenUser.result.expire,
              [AUTHORIZATION_KEY.MEMBER_ID]: tokenUser.result.member_id,
              [AUTHORIZATION_KEY.AVATAR]: tokenUser.result.avatar,
              [AUTHORIZATION_KEY.MEMBER_FULL_NAME]: tokenUser.result.member_full_name,
              [AUTHORIZATION_KEY.ORGANISATION_ID]: tokenUser.result.organisation_id,
              [AUTHORIZATION_KEY.MEMBER_EMAIL]: tokenUser.result.member_email
            };
            this.setStore(setStore2);
          }
        } catch (e) {
          return false;
        }
        try {
          const fbadAppAccessToken = await serviceMember.getFacebookAdsAppAccessToken();
          if (fbadAppAccessToken) {
            const setStore2 = {
              [AUTHORIZATION_KEY.FACEBOOK_ADS_APP_ACCESS_TOKEN]: fbadAppAccessToken.result
            };
            this.setStore(setStore2);
          }
        } catch (e) {
          return false;
        }
        return {
          success: true,
          first_login: firstLogin
        };
      }
    };
    this.refreshToken = async (failedRequest, url, form, key) => {
      try {
        const tokenRefreshResponse = await axios2.post(url, form, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });
        let authorizationHeader = "";
        let tokenType = "";
        let accessToken = "";
        let refreshToken2 = "";
        let jwt = "";
        if (tokenRefreshResponse && tokenRefreshResponse.data) {
          tokenType = tokenRefreshResponse.data.token_type ?? "Bearer";
          accessToken = tokenRefreshResponse.data.access_token ?? "";
          authorizationHeader = authorizationHeader.concat(tokenType).concat(" ").concat(accessToken);
          refreshToken2 = tokenRefreshResponse.data[AUTHORIZATION_KEY.REFRESH_TOKEN] ?? "";
          jwt = tokenRefreshResponse.data[AUTHORIZATION_KEY.JWT] ?? "";
          const isJwtChange = jwt !== this.getStore(AUTHORIZATION_KEY.JWT);
          if (false) {
            return tokenRefreshResponse.data;
          } else {
            const setStore = {
              [key[AUTHORIZATION_KEY.ACCESS_TOKEN]]: accessToken,
              [key[AUTHORIZATION_KEY.TOKEN_TYPE]]: tokenType,
              [key[AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER]]: authorizationHeader,
              [key[AUTHORIZATION_KEY.REFRESH_TOKEN]]: refreshToken2,
              [key[AUTHORIZATION_KEY.JWT]]: jwt
            };
            this.setStore(setStore);
            process.env.REACT_APP_HEADER_JWT === "true" && isJwtChange && location.reload();
          }
        } else {
          return logout();
        }
      } catch (error) {
        logout();
        return Promise.reject(error);
      }
    };
    this.setStore = (key) => {
      Object.keys(key).forEach((_key) => {
        if (!_key) {
          return;
        } else {
          Storage.setItem(_key, key[_key]);
        }
      });
    };
    this.getStore = (key) => {
      return Storage.getItem(key);
    };
  }
};

// src/Gateway/Instance.ts
var AUTHORIZED_CODE_URL = BaseRoute_default.__createRequestURL(
  {
    option: "token",
    api: "oauth2"
  },
  false
);
var clientID = AXIOS_CONFIGS.CLIENT_ID;
var clientSecret = AXIOS_CONFIGS.CLIENT_SECRET;
var AesirXApiInstance = axios3.create({
  baseURL: AXIOS_CONFIGS.BASE_ENDPOINT_URL,
  timeout: 100 * 1e4
});
var refreshToken = (failedRequest) => {
  let refresh_token;
  if (false) {
    refresh_token = process.env.refreshToken;
  } else {
    refresh_token = Storage.getItem(AUTHORIZATION_KEY.REFRESH_TOKEN) ?? "";
  }
  const refreshTokenFormData = new URLSearchParams();
  refreshTokenFormData.append("grant_type", "refresh_token");
  refreshTokenFormData.append("client_id", clientID);
  refreshTokenFormData.append("client_secret", clientSecret);
  refreshTokenFormData.append("refresh_token", refresh_token);
  const key = {
    [AUTHORIZATION_KEY.ACCESS_TOKEN]: [AUTHORIZATION_KEY.ACCESS_TOKEN],
    [AUTHORIZATION_KEY.TOKEN_TYPE]: [AUTHORIZATION_KEY.TOKEN_TYPE],
    [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER]: [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER],
    [AUTHORIZATION_KEY.REFRESH_TOKEN]: [AUTHORIZATION_KEY.REFRESH_TOKEN],
    [AUTHORIZATION_KEY.JWT]: [AUTHORIZATION_KEY.JWT]
  };
  const request = new AesirxAuthenticationApiService();
  request.refreshToken(failedRequest, AUTHORIZED_CODE_URL, refreshTokenFormData, key);
};
var refreshAuthLogic = (failedRequest) => refreshToken(failedRequest);
createAuthRefreshInterceptor(AesirXApiInstance, refreshAuthLogic, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true
});
var pending = {};
var CancelToken = axios3.CancelToken;
var removePending = (config, f) => {
  if (config) {
    const url = config.url.replace(config.baseURL, "/");
    const flagUrl = url + "&" + config.method + "&" + queryString.stringify(config.params);
    if (flagUrl in pending) {
      if (f) {
        f();
      } else {
        delete pending[flagUrl];
      }
    } else {
      if (f) {
        pending[flagUrl] = f;
      }
    }
  }
};
AesirXApiInstance.interceptors.request.use(
  function(config) {
    let accessToken = "";
    let jwt = "";
    if (false) {
      accessToken = process.env.accessToken;
    } else {
      accessToken = Storage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);
      jwt = Storage.getItem(AUTHORIZATION_KEY.JWT);
    }
    if (config.method === "post" || config.method === "put") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    if (accessToken || jwt) {
      config.headers = {
        Authorization: "Bearer " + (env?.REACT_APP_HEADER_JWT === "true" ? jwt : accessToken),
        ...config.headers
      };
    }
    config.params = config.params || {};
    config.params["time"] = Math.floor(Date.now() / 1e3);
    config.cancelToken = new CancelToken((c) => {
      removePending(config, c);
    });
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
AesirXApiInstance.interceptors.response.use(
  (response) => {
    removePending(response.config, "");
    return response.data;
  },
  (error) => {
    removePending(error.config, "");
    return Promise.reject(error);
  }
);
var Instance_default = AesirXApiInstance;

// src/Member/MemberRoute.ts
var MemberRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.createMemberRequest = (data) => {
      data.endpoint_url = this.createRequestURL({
        option: "member"
      });
      return InstanceServiceApi_default.post("/api/member", data);
    };
    this.activateMemberRequest = (data) => {
      data.endpoint_url = this.createRequestURL({
        option: "member"
      });
      return InstanceServiceApi_default.put("/api/member", data);
    };
    this.getTokenByUserRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "getTokenByUser"
        }),
        {
          headers: {
            Authorization: "Bearer " + Storage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN)
          }
        }
      );
    };
    this.getFacebookAdsAppAccessToken = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "member",
          task: "getFacebookAdsAppAccessToken"
        }),
        {
          headers: {
            Authorization: "Bearer " + Storage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN)
          }
        }
      );
    };
    /**
     *
     * @param data
     */
    this.updateMemberRequest = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "member"
        }),
        data
      );
    };
    /**
     *
     * @param memberId
     */
    this.getMemberInfoRequest = (memberId) => Instance_default.get(
      this.createRequestURL({
        option: "member",
        id: memberId
      })
    );
    /**
     *
     * @param memberId
     */
    this.resendActivationEmailRequest = (memberId) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "resendActivationEmail"
        }),
        {
          memberId
        }
      );
    };
    this.processResetRequest = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "processResetRequest"
        }),
        {
          data
        }
      );
    };
    this.processResetConfirm = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "processResetConfirm"
        }),
        {
          data
        }
      );
    };
    this.processResetComplete = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "processResetComplete"
        }),
        {
          data
        }
      );
    };
    this.checkUsername = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "checkUsername"
        }),
        {
          data
        }
      );
    };
    this.checkEmail = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "member",
          task: "checkEmail"
        }),
        {
          data
        }
      );
    };
  }
};
var MemberRoute_default = MemberRoute;

export {
  MemberRoute_default,
  AesirxMemberApiService,
  AesirxAuthenticationApiService,
  Instance_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
